<template>
    <div>
        <Table :columns="columns" :data="resources" size="small" stripe>
        </Table>
    </div>
</template>
<script>
export default {
    props: {
        row: Object
    },
    computed: {
        resources () {
            return this.row.kot_printers
        },
        columns () {
            return [
                {
                    title: this.$t('productGroup.printer'),
                    key: 'printer_kot',
                    align: 'center'
                },
                {
                    title: this.$t('productGroup.ipAddress'),
                    key: 'printer_ip_address',
                    align: 'center'
                },
                {
                    title: this.$t('productGroup.port'),
                    key: 'port',
                    align: 'center'
                },
                {
                    title: this.$t('productGroup.type'),
                    key: 'input_type',
                    align: 'center'
                }
            ]
        }
    }
}
</script>
