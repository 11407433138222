<template>
    <div class="tw-space-y-2">
        <div class="row">
            <div class="col-md-12">
                <label class="ts-text-xs">{{
                    $t('productGroup.category')
                }}</label>
                <Select
                    v-model="model.category_id"
                    class="tw-w-full"
                    filterable
                >
                    <Option
                        v-for="category in categories"
                        :value="category.category_id"
                        :key="category.category_id"
                    >
                        {{ category.category_name_en }}
                    </Option>
                </Select>
                <div
                    class="validate tw-text-xs"
                    v-if="errors.has('category_id')"
                >
                    {{ errors.first('category_id') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label class="tw-text-xs">{{
                    $t('productGroup.nameKh')
                }}</label>
                <input
                    v-model="model.pro_group_name_kh"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': errors.has('pro_group_name_kh')
                    }"
                />
                <div
                    class="invalid-feedback tw-text-xs"
                    v-if="errors.has('pro_group_name_kh')"
                >
                    {{ errors.first('pro_group_name_kh') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label class="required tw-text-xs">{{
                    $t('productGroup.nameEn')
                }}</label>
                <input
                    v-model="model.pro_group_name_en"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': errors.has('pro_group_name_en')
                    }"
                />
                <div
                    class="invalid-feedback tw-text-xs"
                    v-if="errors.has('pro_group_name_en')"
                >
                    {{ errors.first('pro_group_name_en') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label class="tw-text-xs">{{ $t('productGroup.icon') }}</label>
                <input
                    v-model="model.icon"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': errors.has('icon')
                    }"
                />
                <small
                    ><i
                        ><a
                            href="https://fontawesome.com/v5/search?o=r&m=free"
                            target="_blank"
                            >{{ $t('productGroup.findYourIcon') }}</a
                        ></i
                    ></small
                >
                <div
                    class="invalid-feedback tw-text-xs"
                    v-if="errors.has('icon')"
                >
                    {{ errors.first('icon') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 tw-mt-3 tw-text-right">
                <ts-button
                    class="tw-mr-2"
                    @click.prevent="() => $emit('cancel')"
                >
                    {{ $t('cancel') }}
                </ts-button>
                <ts-button
                    v-if="!isUpdate"
                    color="primary"
                    @click.prevent="onSave"
                    :waiting="waiting"
                    >{{ $t('save') }}</ts-button
                >
                <ts-button
                    v-if="isUpdate"
                    color="primary"
                    @click.prevent="onUpdate"
                    :waiting="waiting"
                    >{{ $t('update') }}</ts-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapState } from 'vuex'
import { isEmpty } from 'lodash'

export default {
    name: 'warehouseForm',
    data () {
        return {
            errors: new Errors(),
            waiting: false,
            waiting_new: false,
            model: {
                pro_group_id: 0,
                category_id: null,
                pro_group_name_kh: null,
                pro_group_name_en: null,
                icon: null
            }
        }
    },
    created () {
        this.fetchData()
        this.setEditData()
    },
    computed: {
        ...mapState('product/productGroup', ['edit_data', 'categories']),
        isUpdate () {
            return !isEmpty(this.edit_data)
        }
    },
    methods: {
        fetchData () {
            this.loading = true
            this.$store
                .dispatch('product/productGroup/getCategory')
                .finally(() => {
                    this.loading = false
                })
        },
        onSave () {
            this.waiting = true
            this.$store
                .dispatch('product/productGroup/store', this.model)
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.$emit('cancel')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        onUpdate () {
            this.waiting = true
            this.$store
                .dispatch('product/productGroup/update', {
                    id: this.model.category_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.$emit('cancel')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        clearInput () {
            this.model.pro_group_id = 0
            this.model.category_id = null
            this.model.pro_group_name_kh = null
            this.model.pro_group_name_en = null
            this.model.icon = null
        },
        setEditData () {
            if (!isEmpty(this.edit_data)) {
                this.model.pro_group_id = this.edit_data.pro_group_id
                this.model.category_id = this.edit_data.category_id
                this.model.pro_group_name_kh = this.edit_data.pro_group_name_kh
                this.model.pro_group_name_en = this.edit_data.pro_group_name_en
                this.model.icon = this.edit_data.icon
            }
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'PRODUCT GROUP',
                desc: not.text
            })
        }
    }
}
</script>
