<template>
    <div class="tw-space-y-2">
        <div class="row">
            <div class="col-md-12">
                <label class="required tw-text-xs">{{
                    $t('productGroup.printer')
                }}</label>
                <input
                    v-model="model.printer_kot"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('printer_kot') }"
                />
                <div
                    class="invalid-feedback tw-text-xs"
                    v-if="errors.has('printer_kot')"
                >
                    {{ errors.first('printer_kot') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label class="required tw-text-xs">{{
                    $t('productGroup.ipAddress')
                }}</label>
                <input
                    v-model="model.printer_ip_address"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('printer_ip_address') }"
                />
                <div
                    class="invalid-feedback tw-text-xs"
                    v-if="errors.has('printer_ip_address')"
                >
                    {{ errors.first('printer_ip_address') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label class="required tw-text-xs">{{
                    $t('productGroup.port')
                }}</label>
                <input
                    v-model.number="model.port"
                    type="number"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('port') }"
                />
                <div
                    class="invalid-feedback tw-text-xs"
                    v-if="errors.has('port')"
                >
                    {{ errors.first('port') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label class="required tw-text-xs">{{
                    $t('productGroup.type')
                }}</label>
                <Select v-model="model.input_type" class="tw-w-full">
                    <Option value="USB">USB</Option>
                    <Option value="NETWORK">NETWORK</Option>
                </Select>
                <div
                    class="validate tw-text-xs"
                    v-if="errors.has('input_type')"
                >
                    {{ errors.first('input_type') }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 tw-mt-3 tw-text-right">
                <ts-button
                    class="tw-mr-2"
                    @click.prevent="() => $emit('cancel')"
                >
                    {{ $t('cancel') }}
                </ts-button>
                <ts-button
                    color="primary"
                    @click.prevent="onSave"
                    :waiting="waiting"
                    >{{ $t('save') }}</ts-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'

export default {
    name: 'addPrinter',
    data () {
        return {
            errors: new Errors(),
            waiting: false,
            waiting_new: false,
            model: {
                pro_group_id: null,
                printer_kot: null,
                printer_ip_address: null,
                port: null,
                input_type: 'NETWORK'
            }
        }
    },
    methods: {
        onSave () {
            this.waiting = true
            this.$store
                .dispatch('product/productGroup/addPrinter', this.model)
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.$emit('cancel')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        onSaveAddNew () {
            this.waiting_new = true
            this.$store
                .dispatch('product/productGroup/addPrinter', this.model)
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.clearInput()
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting_new = false
                })
        },
        setEditData (record) {
            this.showFormAddPrinter = true
            this.model.pro_group_id = record.pro_group_id
            if (record.kot_printers.length > 0) {
                this.model.printer_kot = record.kot_printers[0].printer_kot
                this.model.printer_ip_address =
                    record.kot_printers[0].printer_ip_address
                this.model.port = record.kot_printers[0].port
                this.model.input_type = record.kot_printers[0].input_type
            }
        },
        clearInput () {
            this.model.pro_group_id = null
            this.model.printer_kot = null
            this.model.printer_ip_address = null
            this.model.port = null
            this.model.input_type = 'NETWORK'
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'PRODUCT GROUP',
                desc: not.text
            })
        }
    }
}
</script>
