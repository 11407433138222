<template>
    <div>
        <ts-page-title :title="$t('productGroup.pageTitle')" />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="d-flex justify-content-between">
                    <Button type="info" ghost @click="addNew">
                        {{ $t('addNew') }}
                    </Button>
                    <div class="tw-justify-end tw-space-x-2">
                        <Input
                            search
                            v-model="search"
                            :placeholder="$t('productGroup.search')"
                            :style="width"
                            @on-focus="() => (width = 'width: 500px')"
                            @on-blur="() => (width = 'width: 300px')"
                        />
                    </div>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="category">
                    {{ row.category ? row.category.category_name_en : '' }}
                </template>
                <template slot-scope="{ row }" slot="icon">
                    <div v-html="row.icon"></div>
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        v-tooltip="$t('productGroup.addPrinter')"
                        class="text-primary ml-2"
                        @click.prevent="addPrinter(row)"
                    >
                        <Icon type="md-add" size="20" />
                    </a>
                    <a
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="edit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="deleteRecord(row)"
                        :transfer="true"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <!-- form-action -->
            <Modal
                v-model="showForm"
                draggable
                scrollable
                :mask="false"
                footer-hide
                width="500px"
                :title="$t('productGroup.pageTitle')"
                centered
                :zIndex="1020"
                :closable="false"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
            <!-- show printer -->
            <Modal
                v-model="showFormAddPrinter"
                draggable
                scrollable
                :mask="false"
                footer-hide
                width="500px"
                :title="$t('productGroup.pageTitle')"
                centered
                :zIndex="1020"
                :closable="false"
            >
                <FromAddPrinter
                    ref="form_add_printer_action"
                    @cancel="onCancel"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { debounce } from 'lodash'
import { mapState } from 'vuex'
import FromAction from './form'
import FromAddPrinter from './form-add-printer'
import expandRow from './table-expand.vue'

export default {
    name: 'warehouseIndex',
    components: {
        FromAction,
        FromAddPrinter
    },
    data () {
        return {
            loading: false,
            showForm: false,
            errors: new Errors(),
            width: 'width: 300px',
            visible: false,
            showFormAddPrinter: false
        }
    },
    computed: {
        ...mapState('product/productGroup', ['resources', 'pagination']),
        search: {
            get () {
                return this.$store.state.product.productGroup.search
            },
            set (newValue) {
                this.$store.commit('product/productGroup/SET_SEARCH', newValue)
                this.$store.commit('product/productGroup/RESET_CURRENT_PAGE')
            }
        },
        columns () {
            return [
                {
                    type: 'expand',
                    render: (h, params) => {
                        return h(expandRow, {
                            props: {
                                row: params.row
                            }
                        })
                    },
                    align: 'center',
                    width: 50
                },
                {
                    title: this.$t('productGroup.nameKh'),
                    key: 'pro_group_name_kh',
                    minWidth: 300,
                    sortable: true
                },
                {
                    title: this.$t('productGroup.nameEn'),
                    key: 'pro_group_name_en',
                    minWidth: 300,
                    sortable: true
                },
                {
                    title: this.$t('productGroup.category'),
                    slot: 'category',
                    minWidth: 250,
                    align: 'center',
                    sortable: true
                },
                {
                    title: this.$t('productGroup.icon'),
                    slot: 'icon',
                    align: 'center',
                    minWidth: 250
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    fixed: 'right',
                    align: 'center',
                    width: 100
                }
            ]
        }
    },
    methods: {
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('product/productGroup/fetch', { ...attributes })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
        },
        addNew () {
            this.$store.commit('product/productGroup/SET_EDIT_DATA', {})
            this.showForm = true
        },
        edit (record) {
            this.showForm = true
            this.$store.commit('product/productGroup/SET_EDIT_DATA', record)
            this.$refs.form_action.setEditData()
        },
        clearEdit () {
            this.$refs.form_action.clearInput()
            this.$store.commit('product/productGroup/SET_EDIT_DATA', {})
            this.showForm = false
        },
        deleteRecord (record) {
            record._deleting = true
            this.$store
                .dispatch('product/productGroup/destroy', record.category_id)
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.fetchData()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    record._deleting = false
                })
        },
        addPrinter (record) {
            this.showFormAddPrinter = true
            this.$refs.form_add_printer_action.setEditData(record)
        },
        onCancel () {
            this.$refs.form_add_printer_action.clearInput()
            this.showFormAddPrinter = false
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'PRODUCT GROUP',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function () {
            this.fetchData()
        }, 500)
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('product/productGroup/RESET_STATE')
        next()
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchData()
        })
    }
}
</script>
